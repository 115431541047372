<template>
  <div class="AppCenter document-content">应用中心</div>
</template>

<script setup>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.AppCenter {
}
</style>